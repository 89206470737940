<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:summary>
      <div class="fuel-total">
        <p>总金额：{{ total.total_money || 0 }} 元</p>
        <p>总油量：{{ total.quantity || 0 }} L</p>
      </div>
    </template>
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            clearable
            placeholder="加油站（可输入）"
            @change="changeSite"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchParams.tanker_id"
            filterable
            clearable
            @change="changeTanker"
            placeholder="加油机名称"
          >
            <el-option
              v-for="item in tankerList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchParams.gun_port"
            filterable
            clearable
            placeholder="油枪编号"
          >
            <el-option
              v-for="item in gunList"
              :key="item.gun_num"
              :value="item.gun_num"
              :label="item.gun_num"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="date"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="时间范围"
            clearable
            @change="getTime"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import { getFuelRecord, ExportFuelRecord } from '@/api/stat';
import { GetMachineList, GetMachineRow } from '@/api/gas_type_check';

import mixins from '@/mixins';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      tableLoading: false,
      searchParams: {},
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 64,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          align: 'left',
          minWidth: 180
        },
        {
          title: '加油机名称',
          key: 'tanker_name',
          minWidth: 92
        },
        {
          title: '油枪编号',
          key: 'gun_port'
        },
        {
          title: '油品',
          key: 'oil_type'
        },
        {
          title: '油量（L）',
          key: 'quantity',
          minWidth: 85
        },
        {
          title: '单价（元/L）',
          key: 'unit_price',
          minWidth: 105
        },
        {
          title: '金额（元）',
          key: 'total',
          minWidth: 90
        },
        {
          title: '加油时间',
          key: 'time',
          minWidth: 100
        }
      ],
      data: [],
      date: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      total: {
        total_money: 0,
        quantity: 0
      },
      tankerList: [],
      gunList: []
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    if (this.$route.query.status) this.searchParams.status = '0';
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      getFuelRecord(params).then(res => {
        let { code, data } = res;
        if (code === 0) {
          this.data = data.list;
          this.total = data.total;

          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        }
        this.tableLoading = false;
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };
      ExportFuelRecord(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    changeSite() {
      // this.ifHaveParams = true;
      this.gunList = [];
      this.tankerList = [];
      GetMachineList({
        site_id: this.searchParams.site_id
      }).then(res => {
        // this.searchParams.tanker_name = '';
        if (res.code == 0) {
          this.tankerList = res.data;
        }
        // this.searchParams.gun_port = '';
        // this.searchParams.tanker_id = '';
        this.searchParams = {
          ...this.searchParams,
          gun_port: '',
          tanker_id: ''
        };
      });
    },
    changeTanker(value) {
      this.gunList = [];
      GetMachineRow({
        tanker_id: value
      }).then(res => {
        this.searchParams.gun_port = '';
        if (res.code == 0) {
          this.gunList = res.data;
        }
      });
    },
    getTime(e) {
      if (e) {
        this.searchParams.begin = e[0];
        this.searchParams.end = e[1];
      } else {
        this.searchParams.begin = '';
        this.searchParams.end = '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fuel-total {
  line-height: 35px;
  background: #01adbe;
  border-radius: 8px;
  // margin-top: 16px;
  margin: 20px 0;
  color: #fff;
  padding: 20px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  p {
    letter-spacing: 1px;
    margin: 0;
    min-width: 200px;
  }

  & p + p {
    margin-left: 32px;
  }
}
</style>
